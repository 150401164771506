import { Link } from 'react-router-dom';

import './header.scss';

export const Header = () => {
  return (
    <header>
      <Link to={'/'}>
        <h1 className="title">Aaron Jacob Ceramics</h1>
      </Link>
      <Link to={'/contact'}>
        <h3>Contact</h3>
      </Link>
      <Link to={'/about'}>
        <h3>About</h3>
      </Link>
    </header>
  );
};
