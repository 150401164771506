import { Header } from '../../common/header';
import './aboutMe.scss';

export const AboutMe = () => {
  return (
    <div className="main-wrapper">
      <Header />
      <h3>Aaron Girard</h3>
      <div className="about-me-text">
        Aaron is a potter based in Santa Monica, CA. His work focuses on large,
        wheel-thrown forms using porcelain and light stonewares. His practice
        explores firing carbon trap shino glazes. He views the kiln as a partner
        in the creation of each piece—with these glazes, the atmosphere of the
        kiln has as much to do with the glaze effect as the ingredients that go
        into each glaze.
      </div>
    </div>
  );
};
