import { Gallery } from 'react-grid-gallery';

import { shuffle } from 'lodash';

import './App.scss';
import { Header } from './common/header';

import allImages from './pages/aaron-jacob-ceramics-image-filenames.json';

const ImageComponent = (props: any) => {
  // Lazy load images on scrolling
  return <img {...props.imageProps} loading="lazy" />;
};

const generate_images = () => {
  const images = allImages.images.map((image) => {
    return {
      src: `https://www.images.aaronjacobceramics.com/${image.filename}`,
      width: image.width,
      height: image.height,
      caption: `Aaron's Cool Ceramics`,
    };
  });

  // Right now we have images 1-189.
  return shuffle(images);
};

function App() {
  const imagess = generate_images();

  return (
    <div className="main-wrapper">
      <Header />
      <div className="body">
        <Gallery
          images={imagess}
          enableImageSelection={false}
          rowHeight={300}
          margin={6}
          thumbnailImageComponent={ImageComponent}
        />
      </div>
    </div>
  );
}

export default App;
