import { Header } from '../../common/header';
import './contact.scss';

export const Contact = () => {
  return (
    <div className="contact-page main-wrapper">
      <Header />
      <div className="body">
        <div className="contact-info">
          <a href="https://www.instagram.com/backwardspottery/">
            Instagram is the best way to reach me
          </a>
        </div>
      </div>
    </div>
  );
};
